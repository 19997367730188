.websiteColors {
    --mnq-error: #972B38;
    --mnq-error-bg: #ffe7e9;
    --mnq-error-border: #FFA690;
    --mnq-success: #23d100;
    --mnq-success-bg: #EAFAE6;
    --mnq-success-border: #A8EA97;
    --mnq-medium: #ffae51;
    --mnq-medium-bg: #FFF4E4;
    --mnq-medium-border: #FFD093;

    --mnq-primary: #972B38;
    --mnq-primary-bg: #ffbfca;
    --mnq-secondary: #fdb913;
    --mnq-secondary-bg: #fdee68;
    --mnq-accessibility-bg: #b98e31;
    --mnq-dark-01: #1D1D1D;
    --mnq-dark-02: #1D1D1D;
    --mnq-bdr-color: #949494;
    --mnq-placeholder: #767676;

    --mnq-light-01: #c2c2c2;
    --mnq-light-02: #dedede;
    --mnq-light-03: #E1E3E6;
    --mnq-light-04: #F2EFE8;
    --mnq-light-05: #fff;
    --mnq-light-06: #f0f0f0;
    --mnq-light-07: #aeaeae;
}

.websiteLayout {
    --radius-1: 4px;
    --radius-2: 8px;
    --radius-round: 100px;
    --shadow-1: 0 0 10px rgba(0, 0, 0, 0.2);
}

.websiteFonts {
    --mnq-font-1: 'Roboto', sans-serif;
}

.ant-avatar {
    border: none;
    line-height: 32px;
    background-color: var(--mnq-primary);
}

.ant-drawer .ant-drawer-header {
    padding: 10px 24px;
}

.customDrawer h2,
.ant-drawer .ant-drawer-title {
    padding-right: 16px;
    font-size: 1.375rem;
    font-weight: 500;
}

.customDrawer h2,
.ant-drawer .ant-drawer-title,
.ant-drawer .ant-drawer-title h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ant-drawer-extra {
    display: flex;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.5);
}

.ant-drawer .ant-drawer-body,
.ant-spin-nested-loading,
.ant-spin-container {
    display: flex;
    flex-direction: column;
}

.drawerContent,
.ant-spin-nested-loading,
.ant-spin-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.ant-drawer .ant-drawer-body {
    padding: 16px;
}

.drawerContent .blkContent {
    flex: 1;
    overflow-y: auto;
    padding: 8px;
}

.drawerContent .blkFooter {
    border-top: 1px solid var(--mnq-light-03);
    display: flex;
    justify-content: flex-end;
    padding: 12px 24px;
    margin: 0 -16px -16px;
}

.drawerContent .blkFooter .ant-form-item {
    margin: 0;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-table-wrapper .ant-table {
    font-family: var(--mnq-font-1);
    font-size: 0.875rem;
}

.ant-pagination-disabled {
    opacity: 0.5;
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
    background-color: var(--mnq-light-05);
    padding: 5px 16px;
    text-transform: uppercase;
    font-size: 0.75rem;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
    padding: 10px 16px;
}

.ant-table-wrapper {
    border: 1px solid var(--mnq-light-06);
    margin-bottom: 10px;
}

.dashboardTbl .tableWrapper .ant-table-wrapper {
    border: none;
    margin: 0;
}

.ant-pagination {
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-item {
    border: 1px solid var(--mnq-bdr-color);
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next {
    padding: 0 16px;
    line-height: 30px;
}

.ant-pagination .ant-pagination-item a {
    text-decoration: none;
}

.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:hover {
    background-color: var(--mnq-primary);
    border-color: var(--mnq-primary);
}

.ant-pagination .ant-pagination-item-active:hover a,
.ant-pagination .ant-pagination-item-active a {
    color: var(--mnq-light-05);
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}

.tableSettingBlk>*:not(:last-child) {
    margin-right: 10px;
}

.tableSettingBlk .ant-input-search {
    width: 270px;
}

.tableSettingBlk .ant-select {
    width: 160px;
}

.tableSettingBlk.userTblSetting .ant-select {
    width: 220px;
}

.ant-drawer-header .ant-btn-default:not(:disabled):hover,
.ant-drawer-header .ant-btn-default:not(:disabled):focus {
    background-color: transparent;
}

.ant-picker:hover,
.ant-input-number:hover,
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
.ant-input-search .ant-input:hover+.ant-input-group-addon .ant-btn,
.ant-input-search .ant-input:focus+.ant-input-group-addon .ant-btn,
.ant-select.ant-select-focused .ant-select-selector {
    border-color: var(--mnq-dark-02);
}

.ant-picker-focused,
.ant-input-number-focused,
.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--mnq-dark-02);
    box-shadow: none;
}

.ant-form-item-control-input-content:focus-within,
.ant-input-affix-wrapper:focus-within,
.ant-select:focus-within {
    outline: 2px solid #c9858a;
    outline-offset: 1px;
    transition: outline-offset 0s, outline 0s;
    border-radius: 3px;
    z-index: 1;
}

.noFocusWithin .ant-form-item-control-input-content:focus-within,
.noFocusWithin .ant-input-affix-wrapper:focus-within,
.noFocusWithin .ant-select:focus-within  {
    outline: none;
}

.blkFooter .ant-form-item-control-input-content:focus-within {
    outline: none;
}

.ant-btn .ant-btn-icon,
.ant-select .ant-select-arrow {
    color: inherit;
    font-size: 0.75rem;
}

.ant-btn-primary:disabled,
.ant-btn-primary .ant-btn-icon.ant-btn-loading-icon {
    color: var(--mnq-light-05);
}

.ant-btn-primary:disabled,
.ant-drawer-content .ant-btn-primary:disabled:hover,
.ant-drawer-content .ant-btn-primary:disabled:focus {
    background-color: var(--mnq-light-07);
    border-color: var(--mnq-light-07);
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-sort,
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: transparent;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child,
.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
    border-radius: 0;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--mnq-light-03);
    margin-bottom: 1px;
}

.ant-form-item {
    margin-bottom: 20px;
}

.ant-modal-body .ant-form-item {
    margin-bottom: 12px;
}

.selectQst .ant-form-item {
    margin-bottom: 0;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
    padding-bottom: 3px;
}

.pageOption {
    display: flex;
    align-items: center;
}

.pageOption>* {
    margin-right: 8px;
}

.pageOption select {
    width: 80px;
}

.pageOption .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.pageOption .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 32px;
}

.pageOption .ant-select-single .ant-select-selector .ant-select-selection-item,
.pageOption .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding-top: 0;
    padding-bottom: 0;
}


.scrollableTable .ant-table-body {
    overflow-y: auto !important;
}

.ant-input-affix-wrapper>input.ant-input:focus {
    box-shadow: 0 0 0 30px var(--mnq-light-05) inset !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: var(--mnq-dark-02);
}

.ant-form-item-required {
    display: flex;
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-left: 5px;
    order: 1;
}

.ant-form-item .ant-form-item-explain-connected {
    text-align: left;
    font-family: var(--mnq-font-1);
    font-size: 0.75rem;
}

.ant-form-item .ant-form-item-explain-error {
    margin-bottom: 10px;
    margin-top: 3px;
    line-height: 1.2;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
    box-shadow: none;
}

.customRadio .ant-radio-wrapper {
    align-items: center;
    display: flex;
}

.customRadio .ant-radio-wrapper .ant-radio {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
}

.customRadio .ant-radio-wrapper span.ant-radio+* {
    padding: 0;
}

.ant-btn .anticon:not(:first-child) {
    margin-left: 5px;
}

.leftIconBtn .ant-btn .anticon,
.leftIconBtn.ant-btn .anticon,
.ant-btn.iconOnlyBtn .anticon,
.ant-tree-switcher .ant-btn .anticon:not(:first-child),
.ant-input-password-icon.ant-btn .anticon:not(:first-child),
.expanderTitle .ant-btn .anticon:not(:first-child),
.editorBtnWrap .ant-btn .anticon:not(:first-child) {
    margin-left: 0;
}

.actionBtn .anticon:not(:first-child) {
    margin: 0;
    font-size: inherit;
}

.anticon-close-circle {
    color: var(--mnq-dark-02);
}

.ant-select .ant-select-clear {
    color: var(--mnq-dark-02);
}

.anticon-close-circle:hover,
.ant-select .ant-select-clear:hover {
    color: var(--mnq-dark-02);
}

.ant-tag {
    margin: 1px;
}

.ant-tree .ant-tree-node-content-wrapper,
.ant-tree .ant-tree-checkbox+span {
    cursor: default;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree .ant-tree-checkbox+span.ant-tree-node-selected {
    background-color: transparent;
}

.ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
    opacity: 1;
    cursor: grab;
}

.drag-over {
    background-color: var(--mnq-light-04);
}

.drag-over-gap-bottom {
    background-color: var(--mnq-medium-bg);
}

.ant-btn .ant-wave {
    display: none;
}

.progressIndicator .ant-progress-line {
    margin: 0;
}

.progressIndicator .ant-progress .ant-progress-inner {
    margin-top: -2px;
}

.ant-form-item .ant-form-item-label>label::after {
    display: none;
}

.ant-progress-line {
    margin-bottom: 0;
}

.ant-picker-dropdown .ant-picker-cell {
    padding: 2px 0;
}

.ant-select .ant-select-selection-placeholder {
    color: var(--mnq-placeholder);
}

.ant-dropdown-menu-item>svg {
    margin-right: 8px;
    max-width: 14px;
}

.ant-input-affix-wrapper,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
    min-height: 32px;
}

.ant-collapse,
.ant-collapse .ant-collapse-content {
    border: none;
    background: none;
    color: inherit;
}

.ant-collapse>.ant-collapse-item,
.ant-collapse>.ant-collapse-item:last-child {
    margin-bottom: 20px;
    border: 1px solid var(--mnq-light-03);
    border-radius: var(--radius-1);
}

.ant-collapse>.ant-collapse-item:last-child {
    margin-bottom: 0;
}

.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
    border-radius: 0;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    background-color: var(--mnq-light-04);
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid var(--mnq-light-03);
    margin-bottom: -1px;
}

.ant-picker-cell-range-hover::before,
.ant-picker-cell-range-hover-start::before,
.ant-picker-cell-range-hover-end::before {
    background-color: var(--mnq-error-bg) !important;
}

.ant-tag-gold {
    border-color: #956c13;
    color: #956c13;
}

.ant-tag-green {
    border-color: #2e8609;
    color: #2e8609;
}

.ant-tag-red {
    border-color: #cf1322;
}

.ant-input-textarea-show-count .ant-input-data-count {
    bottom: -20px;
    font-size: 0.75rem;
}

.ant-popconfirm .ant-popconfirm-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.ant-collapse>.ant-collapse-item >.ant-collapse-header {
    align-items: center;
}

@media (max-width: 1500px) {
    .ant-pagination .ant-pagination-prev,
    .ant-pagination .ant-pagination-next,
    .ant-table-wrapper .ant-table {
        font-size: 0.75rem;
    }
}
